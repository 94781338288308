<script>
import moment from 'moment'
import { jsPDF } from 'jspdf'
import * as XLSX from 'xlsx'

export default {
  components: {
  },
  TextFormat(valor, tipo, qtdDecimal = 2,TipoFrom = "", TipoTo = "") {
    
    if (valor !== null && valor !== '')
    {
      if (tipo === 'date') {
        return moment(valor, 'YYYY-MM-DD').format('DD/MM/YYYY')
      }

      if (tipo === 'dateFormat') {
        
        if(TipoFrom === 'DD/MM/YYYY' && TipoTo === 'YYYY-MM-DD' ){
        var dia  = valor.split("/")[0];
        var mes  = valor.split("/")[1];
        var ano  = valor.split("/")[2];
        
        return ano + '-' + ("0"+mes).slice(-2) + '-' + ("0"+dia).slice(-2)
        }
        
        return moment(valor, TipoFrom).format(TipoTo)

      }

      if (tipo === 'percent') {
          valor = valor.toString().replace(',', '.')
          valor = Number(valor)
          const val = valor.toFixed(qtdDecimal).replace('.', ',')
          return `${val} %`
        
      }
      if (tipo === 'money') {
        valor = valor.toString().replace(',', '.')
        valor = Number(valor)
        const val = (valor / 1).toFixed(qtdDecimal).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
      }
    }
    return ''
  },
  ExportDoc(DocType,DocName,ElementToExport,DataExport,Formato,Magem){
    
    switch (DocType) 
      {        
        case 'XLSX':  
          this.GerarXlsx(DataExport,DocName)
        break
        case 'PDF':
          DocName = DocName+".pdf"
          const element = document.getElementById(ElementToExport).innerHTML
          this.GerarPdf(Formato,Magem, element, DocName)
        break
        
      }

  },
  GerarPdf(formato = 'A4', margem = [30, 30, 30, 30], element, name) {
    const doc = jsPDF('p', 'pt', formato)
    doc.html(element, {
      margin: margem,
    }).then(() => doc.save(name))
  },
  GerarXlsx(table,name) {
    name = `${name}.xlsx`
    const data = XLSX.utils.json_to_sheet(table)
    const wb = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(wb, data, 'data')
    XLSX.writeFile(wb,name)
  },
  async DownloadFile (DocByte, DocName) {
      const Documento = DocByte
      const link = document.createElement('a')
      link.href = 'data:image;base64,'.concat(link.href.concat(Documento))
      link.download = DocName
      //link.setAttribute('target', '_blank');
      link.click()
  },
  ArryGroupBy(array, key){
  /*
    Criar Agrupamento de um Arry por exemplo, agrupar a Tabela de Preco pela Categoria de Produto:
      this.ArryGroupBy(this.TabRelTabPreco, 'CATEGORIA')
    Vai Retornar uma nova Lista com esse Agrupamento
  */
      const result = {}
      array.forEach(item => {
        if (!result[item[key]]){
          result[item[key]] = []
        }
        result[item[key]].push(item)
      })
      return result
  },
  ReadXLSX(DocExcel,AbaExcel = 0,LinhaHeader = 0) {
  const file  = DocExcel.target.files[0]
  
  return new Promise((resolve, reject) => {
    let reader = new FileReader();

    reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: 'binary', cellText: true, cellDates: true });
          /* Get first worksheet */
          const wsname = wb.SheetNames[AbaExcel];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          //const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          var range = XLSX.utils.decode_range(ws['!ref']);
          range.s.r = LinhaHeader; 
          //const data = XLSX.utils.sheet_to_json(ws,{raw:false,range: range});
          const data = XLSX.utils.sheet_to_json(ws,{raw:false,range: range,dateNF:'yyyy-mm-dd'});

          let DocXLSX = {
            wsname: wsname,
            wsdata: data
          }

          resolve(DocXLSX);
    };

    reader.onerror = reject;

    reader.readAsArrayBuffer(file);
  })
 },
 ReadTXT(File,ReturnType = "") {
  
  const file  = File.target.files[0]

  try{
      if(file['type']==='text/plain' || file['type']==='')
      {
          return new Promise((resolve, reject) => {
          const reader = new FileReader();
          
          reader.onload = (e) => {
            
            if(ReturnType = "Arry"){
              resolve(e.target.result.toString().split(/\r\n|\n/))
            }
            else{
              resolve(e.target.result)
            }
            
          }
        
          reader.onerror = reject;
          //reader.readAsText(file);
          reader.readAsBinaryString(file);
        
         })
      }
  }
  catch(error){
    return null
  }
  
  return null

 },
}

</script>
