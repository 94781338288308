<template>
  <div>
    
    

    <div class="col-12">
      <div class="card mb-4">
        <div class="card-header">
          <h4 class="card-title">
            <span>{{RelName}}</span>
          </h4>
          

          <b-col v-if="showFiltro ? false : true ">
            <div style="float:right">
              <div class="DivBotaoLadoLado" >
              <b-button variant="danger" @click="$router.push('../Relatorios')">
                   <span>RETORNAR</span>
               </b-button>
              </div>
               
               <div class="DivBotaoLadoLado" >
               <b-button v-b-toggle="'collapse-filtro'" variant="primary" >
                 <span>FILTROS</span>
               </b-button>
               </div>
            </div>
          </b-col>


        </div>

        <b-collapse visible  id="collapse-filtro">
        <div class="card-body">
          
          <b-row>
            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Ano:"  >
                  <multiselect
                    v-model="Ano"
                    placeholder="Selecione o Ano"
                    label="name"
                    track-by="code"
                    :options="AnoOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="3" class="mb-1">
              <b-form-group label="Mes:"  >
                  <multiselect
                    v-model="Mes"
                    placeholder="Selecione o Mes"
                    label="name"
                    track-by="code"
                    :options="MesOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Departamento:"  >
                  <multiselect
                    v-model="Departamento"
                    placeholder="Selecione o Departamento"
                    label="name"
                    track-by="code"
                    :options="DepartamentoOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>
          
          </b-row>

          <b-row>
            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Centro de Custo:"  >
                  <multiselect
                    v-model="CentroCusto"
                    placeholder="Selecione o Centro de Custo"
                    label="name"
                    track-by="code"
                    :options="CentroCustoOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>

            <b-col md="6" xl="6" class="mb-1">
              <b-form-group label="Conta Contábil:"  >
                  <multiselect
                    v-model="ContaContabil"
                    placeholder="Selecione a Conta Contábil"
                    label="name"
                    track-by="code"
                    :options="ContaContabilOption"
                    :multiple="true"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
           <b-col md="6" xl="2" class="mb-1">
              <b-form-group label="Exibir Análitico:" >
                <multiselect
                    v-model="ExibeAnalitico"
                    placeholder="Selecione"
                    label="name"
                    track-by="code"
                    :options="ExibeAnaliticoOption"
                    :multiple="false"
                    :taggable="false"
                    :close-on-select="true"
                    :option-height="50"
                    :show-labels="false"
                  ></multiselect>

              </b-form-group>
           
            </b-col>
          </b-row>



           <b-row>
            
         <b-col
                md="6"
                xl="12"
                class="mb-1"
              >
              <div class="DivBotaoLadoLado" >
               <b-button
              variant="danger"
              type="button"
              @click="$router.push('../Relatorios')"
            >
              <span>RETORNAR</span>
            </b-button>
              </div>
              <div class="DivBotaoLadoLado" >
            <b-button v-b-toggle="'collapse-filtro'"
              variant="primary"
              type="submit"
              @click="ConsultarRelatorioFiltro"
            >
              <span>CONSULTAR</span>
            </b-button>
              </div>
          </b-col>
         </b-row>
         
        </div>
        </b-collapse>
        
      </div>
    </div>



 <div class="col-lg-12 col-24">
      <div class="row match-height">
        


    <div class="col-7" v-if="TabDSP_CR1Position >= 0">
            <div class="card mb-2">
              <div class="card-body" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       type="submit"
                       @click="TabDSP_CR1RowBack(-1)"
                       v-if="TabDSP_CR1Position > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div id="DSP_CR1" v-if="TabDSP_CR1Position == 0">
                <h4 class="fw-bolder mb-1">Por Centro de Resultado</h4>  
                
                 <b-table
                  striped
                  responsive
                  hover
                  small
                  sticky-header
                  :items="TabDSP_CR1"
                  :fields="TabDSP_CR1fields"
                  selectable
                  select-mode = "single"
                  @row-selected="TabDSP_CR1onRowSelected"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                
                <template #cell(ORCADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(REALIZADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VARIACAO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                 <template #cell(VARIACAOPERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="1">
                     Total
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR1Total('ORCADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR1Total('REALIZADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR1Total('VARIACAO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR1Total('VARIACAOPERC'),'percent') }}
                    </td>

                   

                  </tr>
                </template>

                </b-table>
                
                <div class="col-3" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('DSP_CR1','TabDSP_CR1')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>

            
             </div>
            </div>
    </div>


    <div class="col-5" v-if="TabDSP_CR1Position >= 0">
            <div class="card mb-2">
              <div class="card-body" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       type="submit"
                       @click="TabDSP_CR1RowBack(-1)"
                       v-if="TabDSP_CR1Position > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div id="DSP_CR1" v-if="TabDSP_CR1Position == 0">
                <h4 class="fw-bolder mb-1">Detalhe por CR</h4>  
                
                 <b-table
                  striped
                  responsive
                  hover
                  small
                  sticky-header
                  :items="TabDSP_CR2"
                  :fields="TabDSP_CR2fields"
                  selectable
                  select-mode = "single"
                  @row-selected="TabDSP_CR2onRowSelected"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                
                <template #cell(ORCADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(REALIZADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VARIACAO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                 <template #cell(VARIACAOPERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="1">
                     Total
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR2Total('ORCADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR2Total('REALIZADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR2Total('VARIACAO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CR2Total('VARIACAOPERC'),'percent') }}
                    </td>

                   

                  </tr>
                </template>

                </b-table>
                
                <div class="col-4" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('DSP_CR2','TabDSP_CR2')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>

            
             </div>
            </div>
    </div>


</div>
</div>



<div class="col-lg-12 col-24">
      <div class="row match-height">
        

    <div class="col-7" v-if="TabDSP_CC1Position >= 0">
            <div class="card mb-2">
              <div class="card-body pb-60" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       type="submit"
                       @click="TabDSP_CC1RowBack(-1)"
                       v-if="TabDSP_CC1Position > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div id="DSP_CR1" v-if="TabDSP_CC1Position == 0">
                <h4 class="fw-bolder mb-1">Por Conta Contabil</h4>  
                
                 <b-table
                  striped
                  responsive
                  hover
                  small
                  sticky-header
                  :items="TabDSP_CC1"
                  :fields="TabDSP_CC1fields"
                  selectable
                  select-mode = "single"
                  @row-selected="TabDSP_CC1onRowSelected"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                
                <template #cell(ORCADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(REALIZADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VARIACAO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                 <template #cell(VARIACAOPERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="1">
                     Total
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CC1Total('ORCADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CC1Total('REALIZADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CC1Total('VARIACAO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CC1Total('VARIACAOPERC'),'percent') }}
                    </td>

                   

                  </tr>
                </template>

                </b-table>
                
                <div class="col-3" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('DSP_CC1','TabDSP_CC1')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>

            
             </div>
            </div>
    </div>

       <div class="col-5" v-if="TabDSP_CC1Position >= 0">
            <div class="card mb-2">
              <div class="card-body pb-60" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       type="submit"
                       @click="TabDSP_CC1RowBack(-1)"
                       v-if="TabDSP_CC1Position > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div id="DSP_CR1" v-if="TabDSP_CC1Position == 0">
                <h4 class="fw-bolder mb-1">Histórico</h4>  
                
                 <b-table
                  striped
                  responsive
                  hover
                  small
                  sticky-header
                  :items="TabDSP_CC_HIST"
                  :fields="TabDSP_CC_HISTfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                >

                
               
                <template #cell(REALIZADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="1">
                     Total
                    </td>

                     <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_CC_HISTTotal('REALIZADO'),'money')}}
                    </td>


                  </tr>
                </template>

                </b-table>
                
                <div class="col-4" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('DSP_CC_HIST','TabDSP_CC_HIST')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>

            
             </div>
            </div>
    </div>

   </div>
</div>



<div class="col-lg-12 col-24"  v-if="ExibeAnalitico != null && ExibeAnalitico.code == 'S'">
  <div class="row match-height">        

    <div class="col-12" v-if="TabDSP_CR1Position >= 0">
            <div class="card mb-2">
              <div class="card-body pb-60" >
                
                <div style="float:right" > 
                   <b-button
                       variant="danger"
                       type="submit"
                       @click="TabDSP_CC1RowBack(-1)"
                       v-if="TabDSP_CR1Position > 0"
                    >
                        <span>VOLTAR</span>
                   </b-button>
                </div>
              
              <div id="DSP_CR1" v-if="TabDSP_CR1Position == 0">
                <h4 class="fw-bolder mb-1">Detalhe Analítico</h4>  
                
                 <b-table
                  striped
                  responsive
                  hover
                  small
                  sticky-header
                  :items="TabDSP_DETALHE"
                  :fields="TabDSP_DETALHEfields"
                  selectable
                  select-mode = "single"
                  table-class = "font-size11"
                  thead-class = "font-size11"
                  thead-tr-class = "font-size11"
                  
                >

                
                <template #cell(ORCADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(REALIZADO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                <template #cell(VARIACAO)="row">
                     {{ TextFormat(row.value,'money') }}
                </template>

                 <template #cell(VARIACAOPERC)="row">
                     {{ TextFormat(row.value,'percent') }}
                </template>
                
                <template v-slot:custom-foot>
                  <tr>
                    <td class="bg-secondary text-white" colspan="3">
                     Total
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_DETALHETotal('ORCADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_DETALHETotal('REALIZADO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white"> 
                      {{ TextFormat(TabDSP_DETALHETotal('VARIACAO'),'money')}}
                    </td>

                    <td class="bg-secondary text-white" colspan="2"> 
                      {{ TextFormat(TabDSP_DETALHETotal('VARIACAOPERC'),'percent') }}
                    </td>

                   

                  </tr>
                </template>

                </b-table>
                
                <div class="col-3" style="float:right"> 
                   <b-form-group
                      label="">
                      <b-form-select
                      v-model="exportarParaSelected"
                      :options="exportarParaOptions"
                      @change="ExportarDoc('DSP_DETALHE','TabDSP_DETALHE')"
                      >
                      </b-form-select>
                   </b-form-group>
                </div>
              </div>

            
             </div>
            </div>
    </div>



   </div>
</div>
  


  </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import VueApexCharts from 'vue-apexcharts'
import BCardCode from '@core/components/b-card-code'
import 'bootstrap-icons/font/bootstrap-icons.css'
import apiPortalSky from '@/services/apiPortalSky'
import AlertaMsg from '@/customPortal/AlertaMsg.vue'
import ConexaoAPI from '@/customPortal/ConexaoAPI.vue'
import Spinner from '@/components/Spinner.vue'
import UtilVUE from '@/customPortal/UtilVUE.vue'

import {
  BFormGroup,
  BRow,
  BCol,
  BButton,
  BTable,
  BFormSelect,
  BFormInput,
  BCollapse,
  BCard,
  VBToggle,
} from 'bootstrap-vue'

export default {
  components: {
    VueApexCharts,
    Multiselect,
    BFormGroup,
    BRow,
    BCol,
    BButton,
    BTable,
    BFormSelect,
    BFormInput,
    BCollapse,
    BCard,
  },
  directives: { 'b-toggle': VBToggle },
  data() {
    return {
      showFiltro: true,
      FiltroRelatorio: [],
      ParametroNivel: [],
      Nivel: [],
      RelName: '',
      RelRota: '',
      BCardCode,
      TabDSP_CR1: [
      ],
      TabDSP_CR1fields: [
        { key: 'DESCRCENCUS', label: 'Centro de Resultado', sortable: true, tdClass: 'td350' },
        { key: 'ORCADO', label: 'Orçado(R$)', sortable: true, tdClass: 'td100' },
        { key: 'REALIZADO', label: 'Realizado(R$)', sortable: true, tdClass: 'td100' },
        { key: 'VARIACAO', label: 'Variação(R$)', sortable: true, tdClass: 'td100' },
        { key: 'VARIACAOPERC', label: '(%)', sortable: true, tdClass: 'td50' },
      ],

      TabDSP_CR2: [
      ],
      TabDSP_CR2fields: [
        { key: 'DTMOV', label: 'DT. Base', sortable: true, tdClass: 'td10' },
        { key: 'ORCADO', label: 'Orçado(R$)', sortable: true, tdClass: 'td10' },
        { key: 'REALIZADO', label: 'Realizado(R$)', sortable: true, tdClass: 'td10' },
        { key: 'VARIACAO', label: 'Variação(R$)', sortable: true, tdClass: 'td10' },
        { key: 'VARIACAOPERC', label: '(%)', sortable: true, tdClass: 'td50' },
      ],
      
      TabDSP_CC1: [
      ],
      TabDSP_CC1fields: [
        { key: 'DESCRCTA', label: 'Conta Contabil', sortable: true, tdClass: 'td200' },
        { key: 'ORCADO', label: 'Orçado', sortable: true, tdClass: 'td100' },
        { key: 'REALIZADO', label: 'Realizado', sortable: true, tdClass: 'td100' },
        { key: 'VARIACAO', label: 'Variação', sortable: true, tdClass: 'td100' },
        { key: 'VARIACAOPERC', label: '(%)', sortable: true, tdClass: 'td100' },
      ],

      TabDSP_CC_HIST: [
      ],
      TabDSP_CC_HISTfields: [
        { key: 'COMPLHIST', label: 'Histórico', sortable: true, tdClass: 'td300' },
        { key: 'REALIZADO', label: 'Realizado', sortable: true, tdClass: 'td100' },
      ],

      TabDSP_DETALHE: [
      ],
      TabDSP_DETALHEfields: [
        { key: 'DATA', label: 'DT. Base', sortable: true, tdClass: 'td10' },
        { key: 'DESCRCENCUS', label: 'Centro de Resultado', sortable: true },
        { key: 'DESCRCTA', label: 'Conta Contabil', sortable: true },
        { key: 'ORCADO', label: 'Orçado(R$)', sortable: true },
        { key: 'REALIZADO', label: 'Realizado(R$)', sortable: true },
        { key: 'VARIACAO', label: 'Variação(R$)', sortable: true},
        { key: 'VARIACAOPERC', label: '(%)', sortable: true, tdClass: 'td100'},
        { key: 'COMPLHIST', label: 'Histórico', sortable: true},
      ],

      Mes: [],
      Ano: [],
      Departamento: [],
      CentroCusto: [],
      ContaContabil: [],
      
      AnoOption: [],
      MesOption: [],
      DepartamentoOption: [],
      CentroCustoOption: [],
      ContaContabilOption: [],

      ExibeAnalitico: { "name": "Não", "code": "N" },
      ExibeAnaliticoOption: [
        { name: 'Não', code: 'N' },
        { name: 'Sim', code: 'S' },
      ], 

      exportarParaSelected: 'Exporte',
      exportarParaOptions: [
        { value: 'Exporte', text: 'Exporte' },
        { value: 'XLSX', text: 'Excel' },
      ],
      TabDSP_CR1Position: -1,
      TabDSP_CC1Position: -1,
    }
  },
  methods: {
    async MostraReport() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Report/MostraReport?CODUSU=${usuario.codusu}&ROTA=${this.RelRota}`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data != null) {
            this.RelName = response.data.descricaoRelatorio
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarOpcoes() {
      let notification = ""
      Spinner.StarLoad()
      const usuario = this.$session.get('usuario')
      
      let ParametroJson = ""
      let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Departamento`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.DepartamentoOption = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
      })

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=CentroCusto`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.CentroCustoOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }

      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=ContaContabil`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.ContaContabilOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }


      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Ano`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.AnoOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }



      if(notification === '') {

        let ParametroJson = ""
        let URLpost = `/Opcoes/ConsultaOpcoes?CODUSU=${usuario.codusu}&OPCAO=Mes`
        await apiPortalSky.post(URLpost, ParametroJson).then(response => {
          if (response.status === 200) {
            if (response.data.length > 0) {
              this.MesOption = response.data
            } else {
              notification = ConexaoAPI.MsgFiltroSemRetorno()
              AlertaMsg.FormataMsg(notification)
            }
          }
        }).catch(error => {
          notification = ConexaoAPI.ValidaErro(error)
          AlertaMsg.FormataMsg(notification)
        })
      
      }



      if(notification === '') {
       Spinner.EndLoad()
      }

    },
    async ConsultarRelatorioFiltro() {
      this.ParametroNivel = []
      this.ConsultarRelatorio()
    },
    async ConsultarRelatorio() {
      Spinner.StarLoad()

      //Inicio Dados Base Relatorio
      this.showFiltro = false
      const usuario = this.$session.get('usuario')
      let notification = ""
      this.FiltroRelatorio = []
      this.Nivel = ""
      //Fim Dados Base Relatorio
      
      
      //Inicio Limpa Dados Retorno
      // Fim Limpa Dados Retorno

      //Inicio Volta Todos Niveis para posição inicial
      this.TabDSP_CR1RowBack(0)
      this.TabDSP_CC1RowBack(0)
      //Fim Volta Todos Niveis para posição inicial
           
      //Inicio Pega parametros de Parametros

     
      this.Ano.forEach(fAno => {
        const FiltroAno = {
          parametro: 'Ano',
          valor: fAno.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroAno)
      })

      this.Mes.forEach(fMes => {
        const FiltroMes = {
          parametro: 'Mes',
          valor: fMes.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroMes)
      })
      

      this.Departamento.forEach(fDepartamento => {
        const FiltroDepartamento = {
          parametro: 'Departamento',
          valor: fDepartamento.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroDepartamento)
      })
  
      this.CentroCusto.forEach(fCentroCusto => {
        const FiltroCentroCusto = {
          parametro: 'CentroCusto',
          valor: fCentroCusto.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroCentroCusto)
      })
      

      this.ContaContabil.forEach(fContaContabil => {
        const FiltroContaContabil = {
          parametro: 'ContaContabil',
          valor: fContaContabil.code.toString(),
        }
        this.FiltroRelatorio.push(FiltroContaContabil)
      })

      let ConsultarDetalhe = 'N'
      if(this.ExibeAnalitico != null){
        if(Array.isArray(this.ExibeAnalitico) == false)
        {
          ConsultarDetalhe = this.ExibeAnalitico.code
        }
      }

      
      //Fim Pega parametros de Parametros


      //Inicio Pega parametros de Parametros de Nivel
       if(this.ParametroNivel != '' && this.ParametroNivel.length > 0) {
            
            this.ParametroNivel.forEach(ParametroNv => {             
                   this.FiltroRelatorio.forEach(ParametroFt => {
                          if(ParametroFt.parametro == ParametroNv.parametro){
                              this.RemoveParametroFiltro(ParametroFt.parametro)
                           }
                   })
                    
                   if(ParametroNv.Nivel != undefined) {
                    this.FiltroRelatorio.push(ParametroNv)
                    this.Nivel += ParametroNv.Nivel + " "                      
                  }
            })

       }
       //Fim Pega parametros de Parametros de Nivel


      let ParametroJson = ''
      let URLpost = ''
      
      if(notification === '' && this.Nivel.includes('DSP_CR1') == false) {
         
         this.TabDSP_CR1 = [] //Limpa tabela Retorno

         ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
         URLpost = `/Report/ExecutaRelDespesasCRCC?CODUSU=${usuario.codusu}&Nivel=DSP_CR1`
         await apiPortalSky.post(URLpost, ParametroJson).then(response => {
           if (response.status === 200) {
             if (response.data.length > 0) {
               this.TabDSP_CR1 = response.data
             } else {
               notification = ConexaoAPI.MsgFiltroSemRetorno()
               AlertaMsg.FormataMsg(notification)
               
             }
           }
         }).catch(error => {
           notification = ConexaoAPI.ValidaErro(error)
           AlertaMsg.FormataMsg(notification)
           
         })
      }


      if(notification === '' && this.Nivel.includes('DSP_CR2') == false) {
      this.TabDSP_CR2 = [] // Limpa Tabela Retorno
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelDespesasCRCC?CODUSU=${usuario.codusu}&Nivel=DSP_CR2`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabDSP_CR2 = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })
  
    }

      
    if(notification === '' && this.Nivel.includes('DSP_CC1') == false) {
      this.TabDSP_CC1 = [] // Limpa Tabela Retorno
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelDespesasCRCC?CODUSU=${usuario.codusu}&Nivel=DSP_CC1`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabDSP_CC1 = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })
  
    }


    if(notification === '' && this.Nivel.includes('DSP_CC_HIST') == false) {
      this.TabDSP_CC_HIST = [] // Limpa Tabela Retorno
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelDespesasCRCC?CODUSU=${usuario.codusu}&Nivel=DSP_CC_HIST`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabDSP_CC_HIST = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })
  
    }


    if(notification === '' && ConsultarDetalhe == 'S') {
      this.TabDSP_DETALHE = [] // Limpa Tabela Retorno
      ParametroJson = JSON.parse(JSON.stringify(this.FiltroRelatorio))
      URLpost = `/Report/ExecutaRelDespesasCRCC?CODUSU=${usuario.codusu}&Nivel=DSP_DETALHE`
      await apiPortalSky.post(URLpost, ParametroJson).then(response => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.TabDSP_DETALHE = response.data
          } else {
            notification = ConexaoAPI.MsgFiltroSemRetorno()
            AlertaMsg.FormataMsg(notification)
            
          }
        }
      }).catch(error => {
        notification = ConexaoAPI.ValidaErro(error)
        AlertaMsg.FormataMsg(notification)
        
      })
  
    }



     if(notification === '') {
       Spinner.EndLoad()
     }

    },
    TabDSP_CR1Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'ORCADO':
          Valor = this.TabDSP_CR1.reduce((accum, TabDSP_CR1) => { return accum + TabDSP_CR1.ORCADO }, 0.0)
        break
        case 'REALIZADO':
          Valor = this.TabDSP_CR1.reduce((accum, TabDSP_CR1) => { return accum + TabDSP_CR1.REALIZADO }, 0.0)
        break
        case 'VARIACAO':
          Valor = this.TabDSP_CR1.reduce((accum, TabDSP_CR1) => { return accum + TabDSP_CR1.VARIACAO }, 0.0)
        break
        case 'VARIACAOPERC':
          let ORCADO = this.TabDSP_CR1.reduce((accum, TabDSP_CR1) => { return accum + TabDSP_CR1.ORCADO }, 0.0)
          let REALIZADO = this.TabDSP_CR1.reduce((accum, TabDSP_CR1) => { return accum + TabDSP_CR1.REALIZADO }, 0.0)
     
          if(ORCADO === 0){
            ORCADO = REALIZADO
          }  

          if(ORCADO > 0){
            Valor =  (100/ORCADO)*REALIZADO
          }
          
        break
       
        
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabDSP_CR2Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'ORCADO':
          Valor = this.TabDSP_CR2.reduce((accum, TabDSP_CR2) => { return accum + TabDSP_CR2.ORCADO }, 0.0)
        break
        case 'REALIZADO':
          Valor = this.TabDSP_CR2.reduce((accum, TabDSP_CR2) => { return accum + TabDSP_CR2.REALIZADO }, 0.0)
        break
        case 'VARIACAO':
          Valor = this.TabDSP_CR2.reduce((accum, TabDSP_CR2) => { return accum + TabDSP_CR2.VARIACAO }, 0.0)
        break
        case 'VARIACAOPERC':
          let ORCADO = this.TabDSP_CR2.reduce((accum, TabDSP_CR2) => { return accum + TabDSP_CR2.ORCADO }, 0.0)
          let REALIZADO = this.TabDSP_CR2.reduce((accum, TabDSP_CR2) => { return accum + TabDSP_CR2.REALIZADO }, 0.0)
     
          if(ORCADO === 0){
            ORCADO = REALIZADO
          }  

          if(ORCADO > 0){
            Valor =  (100/ORCADO)*REALIZADO
          }
          
        break
       
        
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabDSP_CC1Total(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'ORCADO':
          Valor = this.TabDSP_CC1.reduce((accum, TabDSP_CC1) => { return accum + TabDSP_CC1.ORCADO }, 0.0)
        break
        case 'REALIZADO':
          Valor = this.TabDSP_CC1.reduce((accum, TabDSP_CC1) => { return accum + TabDSP_CC1.REALIZADO }, 0.0)
        break
        case 'VARIACAO':
          Valor = this.TabDSP_CC1.reduce((accum, TabDSP_CC1) => { return accum + TabDSP_CC1.VARIACAO }, 0.0)
        break
        case 'VARIACAOPERC':
          let ORCADO = this.TabDSP_CC1.reduce((accum, TabDSP_CC1) => { return accum + TabDSP_CC1.ORCADO }, 0.0)
          let REALIZADO = this.TabDSP_CC1.reduce((accum, TabDSP_CC1) => { return accum + TabDSP_CC1.REALIZADO }, 0.0)
     
          if(ORCADO === 0){
            ORCADO = REALIZADO
          }  

          if(ORCADO > 0){
            Valor =  (100/ORCADO)*REALIZADO
          }
          
        break
       
        
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabDSP_CC_HISTTotal(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'REALIZADO':
          Valor = this.TabDSP_CC_HIST.reduce((accum, TabDSP_CC_HIST) => { return accum + TabDSP_CC_HIST.REALIZADO }, 0.0)
        break       
       
        
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
    TabDSP_DETALHETotal(Coluna) {
      let Valor  = 0

      switch (Coluna) {
        case 'ORCADO':
          Valor = this.TabDSP_DETALHE.reduce((accum, TabDSP_DETALHE) => { return accum + TabDSP_DETALHE.ORCADO }, 0.0)
        break
        case 'REALIZADO':
          Valor = this.TabDSP_DETALHE.reduce((accum, TabDSP_DETALHE) => { return accum + TabDSP_DETALHE.REALIZADO }, 0.0)
        break
        case 'VARIACAO':
          Valor = this.TabDSP_DETALHE.reduce((accum, TabDSP_DETALHE) => { return accum + TabDSP_DETALHE.VARIACAO }, 0.0)
        break
        case 'VARIACAOPERC':
          let ORCADO = this.TabDSP_DETALHE.reduce((accum, TabDSP_DETALHE) => { return accum + TabDSP_DETALHE.ORCADO }, 0.0)
          let REALIZADO = this.TabDSP_DETALHE.reduce((accum, TabDSP_DETALHE) => { return accum + TabDSP_DETALHE.REALIZADO }, 0.0)
     
          if(ORCADO === 0){
            ORCADO = REALIZADO
          }  

          if(ORCADO > 0){
            Valor =  (100/ORCADO)*REALIZADO
          }
          
        break
       
        
       
      }
      
      if(!isNaN(Valor))
      {
        return Valor
      }
      else{
        return 0
      }
      
    },
     

     

    RemoveParametroNivel(Nivel){
       let pNivel = []

       this.ParametroNivel.forEach(ParametroNv => {       
          if(ParametroNv.Nivel != Nivel){
            pNivel.push(ParametroNv)
          }
         }
       )

       this.ParametroNivel = []
       this.ParametroNivel = pNivel
    },

    RemoveParametroFiltro(Parametro){
       let pNivel = []

       this.FiltroRelatorio.forEach(ParametroNv => {       
          if(ParametroNv.Nivel != Parametro){
            pNivel.push(ParametroNv)
          }
         }
       )

       this.FiltroRelatorio = []
       this.FiltroRelatorio = pNivel
    },
    
    TabDSP_CR1onRowSelected(item) {
      this.Nivel = 'DSP_CR1'
      this.RemoveParametroNivel(this.Nivel)

      if(item.length > 0)
      {
        if(item[0].CODCENCUS !== "" && item[0].CODCENCUS != undefined) {
          
         let vParametro = {
          Nivel: this.Nivel,
          parametro: 'CentroCusto',
          valor: item[0].CODCENCUS.toString(),
         }
         this.ParametroNivel.push(vParametro)

          this.ConsultarRelatorio()
        } 
      } else {
          this.ConsultarRelatorio()
        }
    },

    TabDSP_CR2onRowSelected(item) {
      this.Nivel = 'DSP_CR2'
      this.RemoveParametroNivel(this.Nivel)

      if(item.length > 0)
      {
        if(item[0].ANO !== "" && item[0].ANO != undefined) {

         let vParametro = {
          Nivel: this.Nivel,
          parametro: 'Ano',
          valor: item[0].ANO.toString(),
         }
         this.ParametroNivel.push(vParametro)

         vParametro = {
          Nivel: this.Nivel,
          parametro: 'Mes',
          valor: item[0].MES.toString(),
         }
         this.ParametroNivel.push(vParametro)
         
         this.ConsultarRelatorio()
        
        } 
      } else {
         this.ConsultarRelatorio()
        }
    },

    TabDSP_CC1onRowSelected(item) {
      this.Nivel = 'DSP_CC1'
      this.RemoveParametroNivel(this.Nivel)

      if(item.length > 0)
      {
        
        if(item[0].CODCTACTB !== "" && item[0].CODCTACTB != undefined) {


          let vParametro = {
          Nivel: this.Nivel,
          parametro: 'ContaContabil',
          valor: item[0].CODCTACTB.toString(),
         }
         this.ParametroNivel.push(vParametro)
 
        this.ConsultarRelatorio()
        
        
        } 
      } else {
         this.ConsultarRelatorio()
        }
    },


    TabDSP_CR1RowBack(RowBackTo) {
       if(RowBackTo == -1){
         this.TabDSP_CR1Position = this.TabDSP_CR1Position-1
       }else{
        this.TabDSP_CR1Position = RowBackTo
       }
    },

    TabDSP_CC1RowBack(RowBackTo) {
       if(RowBackTo == -1){
         this.TabDSP_CC1Position = this.TabDSP_CC1Position-1
       }else{
        this.TabDSP_CC1Position = RowBackTo
       }
    },
    
    
    TextFormat(valor, tipo) {
      return UtilVUE.TextFormat(valor, tipo)
    },
    ExportarDoc (ElementToExport,DocName) {
      
      let DataExport = ""
      switch (ElementToExport) {
                case 'DSP_CR1':
                  DataExport = this.TabDSP_CR1
                  break
                case 'DSP_CR2':
                  DataExport = this.TabDSP_CR2
                  break
                case 'DSP_CC1':
                  DataExport = this.TabDSP_CC1
                  break
                case 'DSP_CC_HIST':
                  DataExport = this.TabDSP_CC_HIST
                  break
                case 'DSP_DETALHE':
                  DataExport = this.TabDSP_DETALHE
                  break
                
      }    
      UtilVUE.ExportDoc(this.exportarParaSelected,DocName,ElementToExport,DataExport)
      this.exportarParaSelected = 'Exporte'
      return

    },
  },
  computed: {
  },
  mounted() {
    if (this.$route.name != null) {
      this.RelRota = this.$route.name
      this.MostraReport()
      this.ConsultarOpcoes()
    }
  },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
